@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
    font-family: 'Roboto', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Roboto', sans-serif;  /* Apply Roboto font here */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* New styles for input and textarea */
input, textarea {
    color: black;
    background-color: white;
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 4px;
}

/* Style for dark mode, if needed */
@media (prefers-color-scheme: dark) {
    input, textarea {
        color: white;
        background-color: #333;
        border-color: #555;
    }
}

/* Focus styles for better accessibility */
input:focus, textarea:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0,123,255,.25);
}
